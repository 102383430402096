import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
export const componentName = {
  display: "SkipLink",
  code: "SkipLink"
};
export const _frontmatter = {
  "menuLabel": "Skip Link",
  "sortOrder": 19.3
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`SkipLink`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} storybookLink="https://caesars-ui-storybook.pages.dev/?path=/story/components-skip-link--default-story" mdxType="LivePlayround" />
    <p>{`Press `}<inlineCode parentName="p">{`tab`}</inlineCode>{` to see the `}<inlineCode parentName="p">{`SkipLink`}</inlineCode>{`.`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { SkipLink } from "@caesars-digital/caesars-ui"
`}</code></pre>
    <p>{`The SkipLink component helps control the navigation for keyboard users when tabbing through a site. It's purpose is to give
a user the chance to skip an entire section of the page (imagine having to go through every option in a long list before being able to proceed).`}</p>
    <p>{`Skip links should be used primarily on what would be considered "sections" of the site, anything marked by a `}<inlineCode parentName="p">{`role="section"`}</inlineCode>{`,
or `}<inlineCode parentName="p">{`<section>`}</inlineCode>{` tag, or any other large blocks of grouped elements.`}</p>
    <hr></hr>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10"><span className="text-red-600">*</span> indicates required.</div>
    <Args component={componentName} className="mb-20" mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
    <AccessibilityTable.Principle principle="Percievable" status="ready">
        The Skip Link component ensures that users can perceive the option to skip to specific content. It is styled to be clearly visible when focused, ensuring that it is noticeable against the background.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Operable" status="ready">
        The component is operable using a keyboard by focusing on the link, which allows users to activate it to skip to the designated section of the page. It also supports mouse interactions, making it accessible to users with various input methods.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Understandable" status="ready">
        The link's purpose is clearly communicated through its label and position. The label dynamically reflects the destination, such as "Skip to Main Content," making it easy for users to understand where they will be redirected.
    </AccessibilityTable.Principle>
    <AccessibilityTable.Principle principle="Robust" status="ready">
        The Skip Link component is designed to work across different web browsers and screen readers. It uses standard HTML anchor tags and includes appropriate ARIA attributes, ensuring compatibility and robustness in various environments.
    </AccessibilityTable.Principle>
    </AccessibilityTable>
    

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      